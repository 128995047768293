import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Header from "./components/header/Header";
import Home from "./components/home/Home";
import Calendar from "./components/calendar/Calendar";
import Privacy from './components/privacy/Privacy';
import Terms from './components/privacy/Terms';
import Guidelines from "./components/guidelines/campaigns/Guidelines";
import ScrollTop from "./utils/ScrollTop";
import './App.scss';

function App() {
    const [stickyHeader, setStickyHeader] = useState(false);

    const handleScroll = () => {
        if (window.scrollY >= 70) {
            setStickyHeader(true)
        } else {
            setStickyHeader(false)
        }
    }

    window.addEventListener('scroll', handleScroll, true)

    return <div className="App">
        <div className={`${stickyHeader ? "header-component-scroll" : ''} header-component w-100`}>
            <Header />
        </div>
        <Routes>
            <Route path="/">
                <Route index element={<Home />} />
                <Route path="/demo_calendar" exact={true} element={<Calendar />} />
                <Route path="/privacy" exact={true} element={<Privacy />} />
                <Route path="/terms" exact={true} element={<Terms />} />
                <Route path="/guidelines/campaigns" exact={true} element={<Guidelines />} />
            </Route>
        </Routes>
        <ScrollTop />
    </div>;
}

export default App;
