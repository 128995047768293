module.exports.GUIDLINES = [
    {
        title: "Campaign Guidelines",
        icon: "fa fa-bullhorn",
        content: [
            "Videos must be 6-15 seconds in length.",
            "Videos must be original content created specifically for this campaign. No re-posting or re-using existing videos.",
            "Videos must be appropriate for all audiences. No explicit/mature/offensive content.",
            "Videos must include clear branding, product imagery, logos, etc. related to the campaign brand.",
            "Videos cannot contain copyrighted music/audio unless you have rights to use it."
        ]
    },
    {
        title: "Participation Rules",
        icon: "fa fa-hand-paper-o",
        content: [
            "One video entry per person/account for this campaign.",
            "By submitting, you agree to allow the brand to re-share your video on their channels if selected as a winner."
        ]
    },
    {
        title: "Selection Process",
        icon: "fa fa-clipboard",
        content: [
            "10 winners will be selected by the campaign creators from all valid entries.",
            "Winners may be selected based on creativity, brand representation, popularity/engagement, etc.",
            "Decisions by the campaign creator are final."
        ]
    },
    {
        title: "Prizes & Rights",
        icon: "fa fa-trophy",
        content: [
            "10 winners will receive rewards in the form of Rayv tokens",
            "Additional rewards like products, gift cards, etc may also be offered",
            "By accepting prize/compensation, winners grant full permission for the brand to utilize their submitted video at their discretion."
        ],
        note: "By submitting a video, you agree to these official rules. Content violating guidelines will be disqualified."
    }
]