import React from 'react';
import { Row, Col } from 'reactstrap';

import { GUIDLINES } from './Data';

import './Guidelines.scss';

export default class Guidelines extends React.Component {
    render() {
        return <section className='section guidelines-container py-5 mt-5' id='guidelines'>
            <Row>
                <Col className='guidelines-content'>
                    {GUIDLINES.map((guideline, guidelineIdx) =>
                        <div key={guidelineIdx} className="px-2 pt-2">
                            <h3 className='py-2 guidelines-heading'><i className={`${guideline.icon}`} /> {guideline.title} :</h3>
                            <ul>
                                {guideline.content.map((guidelineItem, guidelineItemIdx) =>
                                    <li key={guidelineItemIdx} className='guideline-item'>  {guidelineItem} </li>
                                )}
                            </ul>
                        </div>
                    )}
                </Col>
            </Row>
        </section>
    }
}